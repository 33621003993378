<ion-app>
  <ion-content class="has-header">
    <div class="page-content grey_bubble_background">
      <img class="top-logo-2020" src="../../assets/images/top-logo.jpg">
      <div class="p-1" *ngIf="!valid">
        <h2>Achtung</h2>
        <p class="margin-right"><strong>Das leider nicht funktioniert:  </strong> <br>
          Dieser Link ist ungültig, da er entweder bereits verwendet wurde oder abgelaufen ist.  <br>
          Wenn Sie weiterhin Interesse an einer Energieversorgung durch die Stadtwerke Hamm haben, wenden Sie sich bitte an Ihren Kundenberater. </p>
      </div>
      <div class="p-1" *ngIf="valid">
        <h2>Auftragsdaten jetzt absenden</h2>
        <p class="margin-right">Ihre neue Energieversorgung erfolgt zu folgenden Konditionen. Um Ihre Auftragsdaten<br>verbindlich abzusenden, klicken
          Sie bitte auf den Button "Auftragsübermittlung".
        </p>
      </div>
      <ion-row *ngIf="valid">
        <ion-col>
          <div class="overview-container">
            <p><span class="overview-header">Übersicht Vertragsdaten</span> </p>
            <p>
              Name: {{name}}
            </p>
            <p>
              Produkt: {{product}}
            </p>
            <p>
              Grundpreis: {{base_price}}€/Jahr
            </p>
            <p>
              Arbeitspreis: {{work_price}}Ct/lWh
            </p>
            <p>
              Preisgarantie: bis {{price_maintenance}}
            </p>
          </div>
        </ion-col>
        <ion-col>
          <div class="ion-text-center">
            <img class="ghost" src="../../assets/images/transmission_ghost.svg">
          </div>
          <div class="ion-text-center">
            <ion-button *ngIf="valid" mode="md" (click)="sendContract()">Auftragsübermittlung</ion-button>
          </div>
        </ion-col>
        <ion-col>

        </ion-col>
      </ion-row>

    </div>
  </ion-content>
</ion-app>